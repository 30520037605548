<template>
	<div class="admin-container">
		<Head />
		<div class="admin-main flex">
			<div class="admin-aside">
				<UserNavA></UserNavA>
			</div>
			<div class="admin-col admin_table whole_000 whole_fff flex_bd">
				<div class="container">
					<div class="exte-box">
						<div class="exte-hd">
							<el-button type="primary" class="btn" @click="dialogVisible = true">{{$t('tgbtn')}}
							</el-button>
						</div>
						<div class="exte-table">
							<el-table :data="tableData" style="width: 100%">
								<el-table-column prop="cardName" :label="$t('tgth1')">
								</el-table-column>
								<el-table-column prop="cardNo" :label="$t('tgth2')">
								</el-table-column>
								<el-table-column prop="coinId" :label="$t('tgth3')">
								</el-table-column>
								<el-table-column prop="amount" :label="$t('tgth4')">
								</el-table-column>
								<el-table-column prop="count" :label="$t('tgth5')">
								</el-table-column>
								<el-table-column prop="exchangeCount" :label="$t('tgth6')">
								</el-table-column>
								<el-table-column prop="createTime" :label="$t('tgth7')">
								</el-table-column>
								<el-table-column prop="operation" :label="$t('th5')">
									<template slot-scope="scope">
										<el-button @click="handleClick(scope.row)" size="mini" class="btn"
											type="primary">{{$t('tgbtn2')}}</el-button>
									</template>
								</el-table-column>
							</el-table>
							<el-drawer :title="info.cardName" :visible.sync="drawer" :direction="direction"
								size="350px">
								<div class="tg-content">
									<div class="tg-pic" id="promotionImage">
										<img src="../../assets/photo/web/promotionbg1.jpg" alt="">
										<div class="tg-pic-text">
											<p class="tg-pic-code">{{info.cardNo}}</p>
											<p>{{$t('context_title')}}</p>
										</div>
									</div>
									<div class="tg-foot">
										<div>{{$t('imagetips')}}</div>
										<el-button @click="save">{{$t('saveimage')}}</el-button>
										<a id="link"></a>
									</div>
								</div>
							</el-drawer>
						</div>
					</div>
				</div>
				<el-dialog :visible.sync="dialogVisible" width="490px" custom-class="custom-dialog">
					<div slot="title" class="dialog-title"><b>{{$t('tgth2')}}</b></div>
					<div class="dialog-content">
						<div class="transfer-cell">
							<el-input v-model="cardNo" :placeholder="$t('tgplaceholder')"></el-input>
						</div>
					</div>
					<div slot="footer" class="dialog-footer">
						<el-button type="primary" @click="exchange">{{$t('tgbtn3')}}</el-button>
					</div>
				</el-dialog>
			</div>
		</div>
		<Foot />
	</div>
</template>

<script>
	import {
		promotionCard,
		exchangecard
	} from '@/api/api/user'
	import html2canvas from 'html2canvas'
	import UserNavA from '@/components/UserNavA.vue'
	import Head from '@/components/Head.vue'
	import Foot from '@/components/Foot.vue'
	export default {
		name: 'Home',
		components: {
			Head,
			UserNavA,
			Foot
		},
		data() {
			return {
				tableData: [],
				drawer: false,
				direction: 'rtl',
				info: {},
				dialogVisible: false,
				cardNo: ''
			};
		},
		mounted() {
			this.getList()
		},
		methods: {
			getList() {
				promotionCard().then(res => {
					if (res.code == 0) {
						this.tableData = res.data
					}
				})
			},
			handleClick(row) {
				this.info = row
				this.drawer = true
			},
			save() {
				html2canvas(document.querySelector("#promotionImage")).then(canvas => {
					var image = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
					console.log(image)
					// here is the most important part because if you dont replace you will get a DOM 18 exception.
					//save as download without name and extension
					//window.location.href = image;
					var link = document.getElementById('link');
					link.setAttribute('download', this.info.cardName+'.png');
					link.setAttribute('href', canvas.toDataURL("image/png").replace("image/png",
						"image/octet-stream"));
					link.click();
				})
			},
			exchange() {
				if (this.cardNo == "") {
					this.$message(this.$t('tgplaceholder'))
				} else {
					exchangecard({
						cardNo: this.cardNo
					}).then(res => {
						if (res.code == 0) {
							this.dialogVisible = false;
							this.$notify.success({
								title: this.$t("exchangesuccess"),
								message: res.message,
							});
						} else {
							this.$message.error(res.message);
						}
					})
				}
			}
		}
	}
</script>
